import { CellContentType } from './../../../shared/components/table/models/cellContent.model';
import { ReportsType } from './reports.model';

export const ReportsListType = {
  [ReportsType.PULLOUT]: [
    { key: 'no', label: 'NO' },
    { key: 'orderNumber', label: 'WO #' },
    { key: 'billTo', label: 'BILL TO' },
    { key: 'masterBillOfLading', label: 'MBL/BOOKING' },
    { key: 'shippingLine', label: 'SSL' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'containerSize', label: 'SIZE' },
    { key: 'containerType', label: 'TYPE' },
    { key: 'chassisNumber', label: 'CHASSIS #' },
    { key: 'chassisPool', label: 'POOL' },
    { key: 'lastFreeDay', label: 'LFD' },
    { key: 'avaDsc', label: 'DSC' },
    { key: 'avaObl', label: 'OBL' },
    { key: 'avaСus', label: 'CUS' },
    { key: 'tmf', label: 'TMF' },
    { key: 'pulloutLocation', label: 'P/Out LOC' },
    { key: 'pulloutSchedule', label: 'P/Out SCHE' },
    { key: 'driverId', label: 'DRV' },
    { key: 'deliverySchedule', label: 'DILV SCHE' },
    { key: 'city', label: 'CITY' },
    { key: 'state', label: 'STATE' },
    { key: 'weight', label: 'WGT' },
    { key: 'returnLocation', label: 'RTN' },
    { key: 'remarks', label: 'REMARK' }
  ],

  [ReportsType.DELIVERY]: [
    { key: 'no', label: 'NO' },
    { key: 'orderNumber', label: 'WO #' },
    { key: 'billTo', label: 'BILL TO' },
    { key: 'shippingLine', label: 'SSL' },
    { key: 'deliveryNumber', label: 'DELIVERY #' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'containerSize', label: 'SIZE' },
    { key: 'containerType', label: 'TYPE' },
    { key: 'returnFreeDay', label: 'RFD' },
    { key: 'pulloutActualIn', label: 'P/Out IN Actual' },
    { key: 'deliveryLocation', label: 'DILV LOC' },
    { key: 'city', label: 'CITY' },
    { key: 'state', label: 'STATE' },
    { key: 'deliverySchedule', label: 'DLV SCHE' },
    { key: 'dropLive', label: 'LIVE/DROP' },
    { key: 'driverIdDelivery', label: 'DRV(DELIVERY)' },
    { key: 'driverIdCurrent', label: 'DRV(Current)' },
    { key: 'returnLocation', label: 'RTN LOC' },
    { key: 'remarks', label: 'REMARK' }
  ],

  [ReportsType.RETURN]: [
    { key: 'no', label: 'NO' },
    { key: 'billTo', label: 'BILL TO' },
    { key: 'shippingLine', label: 'SSL' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'containerSize', label: 'SIZE' },
    { key: 'containerType', label: 'TYPE' },
    { key: 'chassisNumber', label: 'CHASSIS #' },
    { key: 'chassisPool', label: 'POOL' },
    { key: 'bookingNumber', label: 'BOOKING' },
    { key: 'erd', label: 'ERD' },
    { key: 'cutOff', label: 'CUT OFF' },
    { key: 'returnFreeDay', label: 'RFD' },
    { key: 'pulloutActualIn', label: 'P/Out IN Actual' },
    { key: 'deliveryLocation', label: 'DILV LOC' },
    { key: 'deliveryActualIn', label: 'DILV OUT Actual' },
    { key: 'pulloutLocation', label: 'P/Out LOC' },
    { key: 'returnLocation', label: 'RTN LOC' },
    { key: 'returnSchedule', label: 'RTN SCHE' },
    { key: 'driverId', label: 'DRV' },
    { key: 'remarks', label: 'REMARK' }
  ],

  [ReportsType.DEMURRAGE]: [
    // { key: 'no', label: 'NO' },
    { key: 'orderNumber', label: 'WO #' },
    { key: 'billTo', label: 'BILL TO' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'shippingLine', label: 'SSL' },
    { key: 'lastFreeDay', label: 'LFD' },
    { key: 'pulloutSchedule', label: 'P/OUT SCHE' },
    { key: 'avaDsc', label: 'DSC' },
    { key: 'avaObl', label: 'OBL' },
    { key: 'avaCus', label: 'CUS' },
    { key: 'avaOth', label: 'OTH' },
    { key: 'accountResponsibility', label: 'NGL' },
    { key: 'customerResponsibility', label: 'CUS' },
    { key: 'reason', label: 'REASON' }
  ],

  [ReportsType.PERDIEM]: [
    // { key: 'no', label: 'NO' },
    { key: 'category', label: 'Category' },
    { key: 'orderNumber', label: 'WO #' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'shippingLine', label: 'SSL' },
    { key: 'returnFreeDay', label: 'RFD' },
    { key: 'billTo', label: 'BILL TO' },
    { key: 'returnSchedule', label: 'RTN SCHE' },
    { key: 'returnLocation', label: 'RTN LOC' },
    { key: 'accountResponsibility', label: 'NGL' },
    { key: 'customerResponsibility', label: 'CUS' },
    { key: 'reason', label: 'REASON' }
  ],

  [ReportsType['EARLY-WARNING']]: [
    { key: 'number', label: 'NO' },
    { key: 'createdDate', label: 'CREATED DATE' },
    { key: 'level', label: 'LEVEL' },
    { key: 'category', label: 'CATEGORY' },
    { key: 'orderNumber', label: 'WO #' },
    { key: 'containerNumber', label: 'CONT #' },
    { key: 'warningIssue', label: 'WARNING ISSUE' },
    {
      key: 'status',
      label: 'STATUS',
      content: {
        type: CellContentType.Switcher,
        conditionalRender: (value: string) => value !== 'SOLVED'
      }
    },
    { key: 'updatedDate', label: 'UPDATED DATE' },
    { key: 'time', label: 'TIME' },
    { key: 'user', label: 'USER' }
  ]
};
