






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ReportsType } from '../models';
import { EmailOrderService } from '@/shared/services/email/email.service';
import { CompanyService } from '@/shared/services/mater-data/company.service';

import {
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME
} from '@/utils/date.util';
import { ToastHelper } from '@/utils/toast.util';

import moment from 'moment';

@Component({})
export default class ReportsEmailModal extends Vue {
  @Prop() listItems;
  @Prop() reportType: ReportsType;

  @Watch('listItems', { immediate: true })
  onListUpdate() {
    this.listItem = this.listItems[0];
  }

  ReportsType = ReportsType;

  moment = moment;
  DATE_API_FORMAT = DATE_API_FORMAT;
  DATE_API_FORMAT_WITHOUT_TIME = DATE_API_FORMAT_WITHOUT_TIME;
  selectedCompany: any = {};
  listItem: any;

  headersList = {
    orderNumber: 'WO #',
    containerNumber: 'CONT #',
    poNumber: 'PO #',
    referenceNumber: 'REF #',
    masterBillOfLading: 'MBL #',
    deliveryActualIn: 'Delivery date',
    returnFreeDay: 'RFD',
    lastFreeDay: 'LFD'
  };

  constructor() {
    super();
  }

  mounted() {
    CompanyService.getCompanyById(this.listItem.billTo).then(
      res => (this.selectedCompany = res)
    );
  }

  get subject() {
    if (ReportsType[this.reportType] === ReportsType.DEMURRAGE) {
      const cont = `${this.listItem.containerNumber}`;
      const location = `${this.listItem.pulloutLocation}`;
      const lfd = `${moment(this.listItem.lastFreeDay, DATE_API_FORMAT).format(
        'LL'
      )}`;

      return `${cont}, ${location}, ${lfd}`;
    } else {
      const cont = `${this.listItem.containerNumber}`;
      const location = `${this.listItem.returnLocation}`;
      const rfd = `${moment(
        this.listItem.returnFreeDay,
        DATE_API_FORMAT
      ).format('LL')}`;

      return `${cont}, ${location}, ${rfd}`;
    }
  }

  get emailBody() {
    if (ReportsType[this.reportType] === ReportsType.DEMURRAGE) {
      const containerSection = this.listItems.map(item => {
        return `To avoid Demurrage charge, ${item.containerNumber}
        must be pulled out of a terminal by
        ${moment(item.lastFreeDay, DATE_API_FORMAT).format('LL')}`;
      });

      const ordersSection = this.listItems.map(item => {
        return `
        WO #: ${item.orderNumber};
        CONT #: ${item.containerNumber};
        ${item.poNumber ? `PO #: ${item.poNumber};` : ''}
        ${item.referenceNumber ? `REF #: ${item.referenceNumber};` : ''}
        ${item.masterBillOfLading ? `MBL #: ${item.masterBillOfLading};` : ''}
        LFD #: ${item.lastFreeDay};`;
      });

      return `Dear ${this.listItem.billTo}
      \n
      ${containerSection},
      \n
      Please assist us with getting the hold cleared as soon as possible so that we can retrieve the container.
      \n
      If you have questions, please contact us to have them answered promptly, thank you!
      \n
      ${ordersSection}`;
    } else {
      const containerSection = this.listItems.map(item => {
        return `To avoid Demurrage charge, ${item.containerNumber}
        must be pulled out of a terminal by
        ${moment(item.lastFreeDay, DATE_API_FORMAT).format('LL')}`;
      });

      const ordersSection = this.listItems.map(item => {
        return `
        WO #: ${item.orderNumber};
        CONT #: ${item.containerNumber};
        ${item.poNumber ? `PO #: ${item.poNumber};` : ''}
        ${item.referenceNumber ? `REF #: ${item.referenceNumber};` : ''}
        ${item.masterBillOfLading ? `MBL #: ${item.masterBillOfLading};` : ''}
        Delivery date: ${item.deliveryActualIn};
        RFD #: ${item.returnFreeDay}`;
      });

      return `Dear ${this.listItem.billTo}
      \n
      ${containerSection},
      \n
      Please assist us with returning the subject container as soon as possible.
      \n
      If you have questions, please contact us to have them answered promptly, thank you!
      ${ordersSection}`;
    }
  }

  submitEmail() {
    const emailForm = {
      //TODO fix when API will be ready - this.selectedCompany.email
      to: 'smeshchankin@fleetup.com',
      subject: this.subject,
      body: this.emailBody
    };

    EmailOrderService.sendEmail(emailForm)
      .then(res => {
        ToastHelper.show('Email sent', `${this.subject}`, 8000, 'success');
      })
      .catch(err => {
        ToastHelper.show(
          'Email not sent',
          err.response.data?.error,
          8000,
          'danger'
        );
      })
      .finally(() => this.$bvModal.hide('reports-email-modal'));
  }

  hide() {
    this.$bvModal.hide('reports-email-modal');
  }
}
