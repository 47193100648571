

























































import { maxLength } from 'vuelidate/lib/validators';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';

Component.registerHooks(['validations']);

@Component({})
export default class ReportsRRModal extends Vue {
  @Prop({ default: {} }) listItem;

  form: any = {
    nglModel: 0,
    cusModel: 0,
    reasonModel: ''
  };

  constructor() {
    super();
  }

  mounted() {
    this.form.nglModel = this.listItem.accountResponsibility;
    this.form.cusModel = this.listItem.customerResponsibility;
    this.form.reasonModel = this.listItem.reason;
  }

  validations() {
    return {
      form: {
        nglModel: { maxLength: maxLength(10) },
        cusModel: { maxLength: maxLength(10) },
        reasonModel: { maxLength: maxLength(200) },
        sum: { validTotal: this.validTotal }
      }
    };
  }

  validTotal() {
    return (
      Number(this.form.nglModel) + Number(this.form.cusModel) === 10 ||
      Number(this.form.nglModel) + Number(this.form.cusModel) === 0
    );
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  resetForm() {
    this.$v.form.$reset();
    this.form = {
      nglModel: '',
      cusModel: '',
      reasonModel: ''
    };
  }

  isFormInValid() {
    this.$v.form.$touch();
    return this.$v.form.$anyError;
  }

  async updateRR() {
    if (this.isFormInValid()) {
      return;
    }
    if (
      this.form.nglModel != this.listItem.accountResponsibility ||
      this.form.cusModel != this.listItem.customerResponsibility ||
      this.form.reasonModel != this.listItem.reason
    ) {
      const response = await WorkOrderService.updateResponsibilityReason(
        this.listItem.orderNumber,
        {
          accountResponsibility: this.form.nglModel,
          customerResponsibility: this.form.cusModel,
          reason: this.form.reasonModel
        }
      );
      this.listItem.accountResponsibility = this.form.nglModel;
      this.listItem.customerResponsibility = this.form.cusModel;
      this.listItem.reason = this.form.reasonModel;
      if (response) {
        this.$emit('updateRRModal');
        this.$bvModal.hide('reports-rr-modal');
      }
    }
  }

  closeRR() {
    this.$bvModal.hide('reports-rr-modal');
  }
}
