




























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ReportsModule } from '@/store/index';
import { StatisticsTypes } from '../models';
import { deepClone } from '@/utils/utils';

import { convertDateFieldToAPIFormatWithoutTime } from '@/utils/date.util';
import moment from 'moment';

@Component({})
export default class StatisticsReport extends Vue {
  @Watch('$route')
  onRouterChange() {
    this.reportType = this.$route.params.type.toUpperCase();
    this.reportsModule.loadStatistics();
  }

  @Watch('selectedStatisticsPeriod')
  statisticsPerionUpdate() {
    this.onRouterChange();
  }

  @Watch('statisticsData')
  statisticsDataUpdate() {
    this.mock();
  }

  reportsModule = ReportsModule;
  reportType = '';

  selectedStatisticsPeriod = this.reportsModule.statisticsPeriod;
  options = [
    { text: 'Weekly', value: 'weekly' },
    { text: 'Monthly', value: 'monthly' },
    { text: 'Yearly', value: 'yearly' }
  ];

  statisticsTypes = null;

  constructor() {
    super();
  }

  get statisticsData() {
    return ReportsModule.statistics;
  }

  get weekDays() {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format('MM/DD/YY'));
    }
    return days;
  }

  get headerPeriods() {
    return {
      weekly: [
        { title: 'SUN', value: this.weekDays[0] },
        { title: 'MON', value: this.weekDays[1] },
        { title: 'TUE', value: this.weekDays[2] },
        { title: 'WED', value: this.weekDays[3] },
        { title: 'THU', value: this.weekDays[4] },
        { title: 'FRI', value: this.weekDays[5] },
        { title: 'SAT', value: this.weekDays[6] }
      ],
      monthly: [
        { title: 'Week 1', value: 'W1' },
        { title: 'Week 2', value: 'W2' },
        { title: 'Week 3', value: 'W3' },
        { title: 'Week 4', value: 'W4' },
        { title: 'Week 5', value: 'W5' }
      ],

      yearly: [
        { title: '1', value: '01' },
        { title: '2', value: '02' },
        { title: '3', value: '03' },
        { title: '4', value: '04' },
        { title: '5', value: '05' },
        { title: '6', value: '06' },
        { title: '7', value: '07' },
        { title: '8', value: '08' },
        { title: '9', value: '09' },
        { title: '10', value: '010' },
        { title: '11', value: '011' },
        { title: '12', value: '012' }
      ]
    };
  }

  mock() {
    this.statisticsTypes = deepClone(StatisticsTypes);
    const statisticsData = ReportsModule.statistics;
    for (const type in this.statisticsTypes) {
      statisticsData.forEach(report => {
        this.statisticsTypes[type].category = [
          ...new Set([
            ...this.statisticsTypes[type].category,
            ...Object.keys(report[type])
          ])
        ];

        this.statisticsTypes[type].category.forEach(cat => {
          if (this.statisticsTypes[type].subcategory) {
            if (
              cat != 'All' &&
              report[type] &&
              report[type][cat] &&
              report[type][cat].length
            ) {
              report[type][cat].forEach(item => {
                this.statisticsTypes[type].subcategory = [
                  ...new Set([
                    ...this.statisticsTypes[type].subcategory,
                    ...[item.type]
                  ])
                ];
              });
            }
          }
        });
      });
    }
  }

  getData(date, type, category, subcategory) {
    //EXP date="03/28/2021", type="deliveries", category="UA", subcategory="IMP-REG"
    // console.log(period, type, category, subcategory);
    let cellData: number | string = '';
    let selectedReportByPeriod = {};
    const year = moment().year();
    const month = moment().format('MM');

    if (this.reportsModule.statisticsPeriod == 'weekly') {
      selectedReportByPeriod = ReportsModule.statistics.find(
        item => item.period == convertDateFieldToAPIFormatWithoutTime(date)
      );
    } else if (this.reportsModule.statisticsPeriod == 'monthly') {
      selectedReportByPeriod = ReportsModule.statistics.find(
        item => item.period == `${year}${month}${date}`
      );
    } else if (this.reportsModule.statisticsPeriod == 'yearly') {
      selectedReportByPeriod = ReportsModule.statistics.find(
        item => item.period == `${year}${date}`
      );
    }

    let amount = 0;

    if (category === 'All') {
      if (selectedReportByPeriod) {
        if (subcategory === 'TOTAL') {
          for (const customer in selectedReportByPeriod[type]) {
            selectedReportByPeriod[type][customer].forEach(
              item => (amount += item.count)
            );
          }
        } else {
          for (const customer in selectedReportByPeriod[type]) {
            const element = selectedReportByPeriod[type][customer].find(
              item => item.type == subcategory
            );
            amount = element ? amount + element.count : amount;
          }
        }
      }
    } else {
      if (selectedReportByPeriod && selectedReportByPeriod[type][category]) {
        if (subcategory === 'TOTAL') {
          selectedReportByPeriod[type][category].forEach(element => {
            amount += element.count;
          });
        } else {
          const element = selectedReportByPeriod[type][category].find(
            item => item.type == subcategory
          );
          amount = element?.count;
        }
      }
    }
    cellData = amount || 0;

    return cellData;
  }
}
